<template>
  <div class="w-block__pre-login">
    <!-- pre-login block -->
    <strong
      ><div class="body2 pa-1 pt-3">
        Prochainement, ici, écoutez… <em>emeis</em> Radio, <br />la radio qui
        parle de vous !
      </div></strong
    >
    <div class="body2 pl-2 pr-2">
      <em>emeis</em> RADIO partagera vos témoignages, votre quotidien, votre
      métier, votre expertise, vos fiertés, vos passions mais aussi vos défis et
      les actualités d’emeis…
      <br />
      Un peu de patience… Votre radio dédiée sera bientôt disponible sur cet
      espace. <br />
      <br />
      Restez connectés !
    </div>
  </div>
</template>
<script>
export default {
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>
<style lang="scss">
.body2 {
  color: #111212;
  text-align: center;
  justify-content: center;
}
/* desktops */
@media (min-width: 992px) {
  .body2 {
    font-size: 16px;
  }
}

/* mobiles */
@media (max-width: 768px) {
  .body2 {
    font-size: 12px;
  }
}
</style>
