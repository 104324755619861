const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '673b08a7bf13b23379f60b1c',
    authMode: ['local'],
    authLocalOptions: {
      mode: 'unique_password',
      defaultLogin: 'digital+emeis@mediameeting.fr',
    },
    WFeedBack: {
      //   ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: "J'écris à la radio",
        name: 'idées de sujet',
        type: 'form',
        headCaption: `emeis RADIO, c'est votre radio, par et pour vous ! Suggérez un sujet ou laissez votre dédicace`,
        subCaption: `Je suis informé que emeis traite les données recueillies pour permettre la soumission d’idées. Pour en savoir plus,
      reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message à bien été envoyé.`,
        pseudonym: {
          label: 'Nom Prénom',
          placeholder: 'Dupont Martin',
        },
        recorderForm: [
          {
            label: 'Fonction',
            placeholder: 'Manager',
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Ma suggestion de sujet',
            placeholder: 'Idée de sujet',
            value: '',
            type: 'text-area',
            required: false,
          },
          {
            label: 'Ma dédicace',
            placeholder: 'Dédicace',
            value: '',
            type: 'text-area',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '06 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: true,
          },
          {
            label: 'J’accepte que mon message soit diffusé sur la radio',
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
      secondFeedback: {
        title: 'Je fais une dédicace',
        name: 'Dédicaces',
        type: 'audio',
        maxDuration: 120,
        headCaption: `emeis RADIO, c'est votre radio, par et pour vous ! Partagez votre dédicace`,
        subCaption: `Je suis informé que emeis traite les données recueillies pour permettre la diffusion de dédicaces. Pour en savoir plus,
    reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message à bien été envoyé.`,
        pseudonym: {
          label: 'Nom Prénom',
          placeholder: 'Dupont Martin',
        },
        recorderForm: [
          {
            label: 'Fonction',
            placeholder: 'Manager',
            value: '',
            type: 'text-field',
            required: false,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '06 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: true,
          },
          {
            label: 'J’accepte que mon message soit diffusé sur la radio',
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    enableExperimentalBlocks: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    metadatas: {
      name: `EMEIS RADIO`,
      colorPrimary: '#F28300',
      colorAccent: '#E8315F',
      supportEmailAddress: 'support@emeisradio.com',
    },
    analytics: {
      matomo: '52',
    },
    useShell: [
      'bb-default',
      {
        mode: 'list',
        additionalRoutes: [
          {
            name: 'custom-programme',
            component: '~/excentrics/pages/custom-programme.vue',
            path: '/programme',
            chunkName: 'pages/programme',
          },
        ],
      },
    ],
    drawerNavigation: () => [
      {
        type: 'link',
        icon: 'mdi-calendar-week',
        value: '/programme',
        title: 'Programme',
      },
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
