<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="accent"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="pl-2 pr-2 pt-1 white--text">
          Vous souhaitez mettre un collègue à l’honneur&#x205F;?<br />
          Faire passer un message de sympathie ou de remerciement&#x205F;?
          <br />
          Nous sommes à votre écoute&#x205F;!
        </div>
        <v-btn
          color="white accent--text"
          @click.stop="showSecondFeedbackModal = true"
        >
          J'enregistre ma dédicace
        </v-btn>
        <w-feedback-popup
          id="secondFeedback"
          v-model="showSecondFeedbackModal"
        />
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
      showSecondFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {
    handleOpen(e) {
      this.$store.dispatch('player/changePlayerContent', {
        content: this.$spoke.document('63fcb12f06470e7729aebfa1').lean(),
        noRedirect: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;

  .v-sheet {
    border-radius: 8px;
  }

  .v-btn {
    border-radius: 4px;
  }
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
